body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 10;
}

.map-container {
  border: 1px solid lightblue;
  position: relative;
  min-height: 175;
  overflow: hidden;
}

.map-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.my-map {
  width: 100%;
}

.no-chat {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-size: 30% 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(images/no_chat.png);
}

.not-found {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255);
  background-size: 50% 75%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(images/404.jpg);
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.1);
}

.message-loading {
  z-index: 999;
  background-color: #ffffff;
  padding-top: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.loading {
  margin: auto;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: gray;
}

.thin_scroll {
  width: 4px;
  height: 4px;
  background-color: gray;
  scrollbar-width: thin;
}